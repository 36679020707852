import LayoutContext from '../Layout/LayoutContext';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useLayoutEffect, useRef} from 'react';
import Block from '../../adapters/helpers/Block';
import {enableDisableFocusOnElements} from '../../adapters/helpers/Utils';
import {ZoneConstants} from '../../adapters/helpers/Constants';
import LazyLoad from 'react-lazyload';
import dynamic from 'next/dynamic';
const Image = dynamic(() => import('../Image/Image'));

export default function Zone(props) {
    const block = new Block(props);
    const extraAttributes = props.extraAttributes || {};
    const zoneRef = useRef();

    let ariaHidden = false;
    let className = getClassNames(block);
    const role = block.getFieldValue(ZoneConstants.role);
    const id = block.getFieldValue(ZoneConstants.id);
    const ariaLabel = block.getFieldValue(ZoneConstants.ariaLabel);
    const backgroundImage = block.getFieldValue(ZoneConstants.backgroundImage);

    const children = block.renderChildBlocks({ extraAttributes }, props.onClickCallback, props.customEvent);

    const layoutContext = useContext(LayoutContext);
    const { isContestVisible } = (layoutContext ? layoutContext : {isContestVisible: false});

    const hideZonesIfContestVisible = () => {
        if (block.getFieldValue(ZoneConstants.classNames) === ZoneConstants.zoneSpotlightSliderBodyClassname && isContestVisible) {
            className += ' zoneHidden';
            
        }
        if (block.getFieldValue(ZoneConstants.classNames) === ZoneConstants.zoneSpotlightContestClassname && !isContestVisible) {
            className += ' zoneHidden';
        }
        ariaHidden = className.indexOf('zoneHidden') !== -1;
    
    };

    hideZonesIfContestVisible();
    
    useLayoutEffect(() => {
        setTimeout(() => {
            enableDisableFocusOnElements(zoneRef.current, !ariaHidden);
    
        }, 1);
        
    });
    
    useEffect(() => {
        className = getClassNames(block);
        hideZonesIfContestVisible();
        enableDisableFocusOnElements(zoneRef.current, !ariaHidden);

    }, [isContestVisible]);

    return (backgroundImage ? (
        <div ref={zoneRef}>
            <Image image={backgroundImage} zoneClassName={className} ariaHidden={ariaHidden}>
                {children}
            </Image>
        </div>
    ) : (
        id === 'footer' ?
            <LazyLoad offset={100}>
                <div ref={zoneRef} className={className} id={id ? id : (role === 'main' ? role : null)} aria-label={ariaLabel} aria-hidden={ariaHidden} role={role}>
                    {children}
                </div>
            </LazyLoad> :
        <div ref={zoneRef} className={className} id={id ? id : (role === 'main' ? role : null)} aria-label={ariaLabel} aria-hidden={ariaHidden} role={role}>
            {children}
        </div>
    ));
}

function getClassNames(block) {
    let classNames = ZoneConstants.zone;
    const zoneClassNames = block.getFieldValue(ZoneConstants.classNames);
    if(zoneClassNames) {
        classNames += zoneClassNames;
    }

    return classNames;
}

Zone.propTypes = {
    className: PropTypes.string,
    backgroundImage: PropTypes.object,
    extraAttributes: PropTypes.object,
    onClickCallback:  PropTypes.func,
    customEvent: PropTypes.object
};
